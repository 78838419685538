/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-classes-per-file */
/* eslint-disable max-len */
import React from 'react';

import ReactPlayer from 'react-player';
import { Table, Input, Button, Popconfirm, Divider, Spin } from 'antd';

import { connectProject, initSocket, initProject, loginProject, initUsers, addSub, editSub } from '../../../utils/project';

const util = require('util');

const EditableCell = ({ editable, value, onChange }) => (
  <div>
    {editable
      ? (
        <Input
          style={{
            margin: '-5px 0',
          }}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      )
      : value}
  </div>
);

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [{
      title: 'Line',
      dataIndex: 'key',
      width: '4%',
      render: (text, record) => record.key,
    }, {
      title: 'Begin',
      dataIndex: 'begin',
      render: (text, record) => this.renderColumns(text, record, 'begin'),
    }, {
      title: 'End',
      dataIndex: 'end',
      render: (text, record) => this.renderColumns(text, record, 'end'),
    }, {
      title: 'Created',
      dataIndex: 'created',
      render: (text, record) => record.created,
    }, {
      title: 'Updated',
      dataIndex: 'updated',
      render: (text, record) => record.updated,
    }, {
      title: 'Text',
      dataIndex: 'content',
      width: '40%',
      render: (text, record) => this.renderColumns(text, record, 'content'),
    }, {
      title: 'Operation',
      dataIndex: 'operation',
      render: (text, record) => {
        const { editable } = record;
        return (
          <div className="editable-row-operations">
            {
              editable
                ? (
                  <span>
                    <Button onClick={() => this.save(record.id)}>Save</Button>
                    <Divider type="vertical" />
                    <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.id)}>
                      <Button>Cancel</Button>
                    </Popconfirm>
                  </span>
                )
                : (
                  <span>
                    <Button onClick={() => this.edit(record.id)}>Edit</Button>
                    <Divider type="vertical" />
                    <Button onClick={() => this.delete(record.id)}>Delete</Button>
                  </span>
                )
            }
          </div>
        );
      },
    }];
    this.state = {
      subtitles: [],
      tempSubs: [],
      users: [],
      loading: true,
      project: {
        url: 'null',
      },
    };
    this.cacheData = this.state.subtitles.map((item) => ({
      ...item,
    }));
  }

  async componentDidMount() {
    const checkConnect = await connectProject(this.props.Projectid);
    const socket = initSocket(this.props.Projectid);
    if (checkConnect.success) {
      loginProject(socket, checkConnect.token, (err, resp) => {
        initProject(socket, async (err, project) => {
          const output = project.subtitles.map((s, index) => ({
            key: index + 1,
            id: s._id,
            begin: s.beginTime,
            end: s.endTime,
            created: s.createdBy,
            updated: s.updatedBy,
            content: s.content,
          }));
          this.setState({
            project,
            connection: socket,
          });
          await this.resolveUsers(output, 'init');
          return (
            this.setState({
              loading: false,
            }));
        });
      });
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
      });
      console.log('OK');
    }
  }

  handleAdd = async () => {
    const dataSource = this.state.subtitles;
      addSub(this.state.connection, (error, response) => {
      let key;
      if (dataSource.length === 0) {
        key = 1;
      } else {
        key = dataSource.length + 1;
      }
      const newData = {
        key,
        id: response._id,
        begin: response.beginTime,
        end: response.endTime,
        created: response.createdBy,
        updated: response.updatedBy,
        content: response.content,
      };
      this.setState({
        subtitles: [...dataSource, newData],
      });
      this.edit(newData.id);
    });
  };

  resolveUsers(subtitles, step) {
    this.setState({
      tempSubs: subtitles,
    });
    subtitles.forEach((sub) => {
      if (!this.state.users.some((item) => item.id === sub.created)) {
        console.log(util.inspect(sub));
        const oldUsers = this.state.users;
        this.setState({
          users: [...oldUsers, {
            id: sub.created,
            name: 'null',
          }],
        });
      }
    });
    initUsers(this.state.connection, this.state.users, (error, response) => {
      console.log(util.inspect(response));
      response.forEach((user) => {
        this.state.users.forEach((item) => {
          if (item.id === user._id && item.name === 'null') {
            const update = item;
            update.name = user.username;
          }
        });
      });
      this.state.tempSubs.forEach((sub) => {
        const update = sub;
        this.state.users.forEach((user) => {
          if (sub.created === user.id) {
            update.created = user.name;
          }
          if (sub.updated === user.id) {
            update.updated = user.name;
          }
        });
      });
      if (step === 'init') {
        this.setState((prevState) => ({
          subtitles: prevState.tempSubs,
          tempSubs: [],
        }));
      }
    });
  }

    handleChange(value, id, column) {
	const newData = [...this.state.subtitles];
	const target = newData.filter((item) => id === item.id)[0];
	if (target) {
	    target[column] = value;
	    this.setState({
		subtitles: newData,
	    });
	}
    }

  edit(id) {
    const newData = [...this.state.subtitles];
    const target = newData.filter((item) => id === item.id)[0];
    if (target) {
      target.editable = true;
      this.setState({
        subtitles: newData,
      });
    }
  }

  delete(id) {
    const newData = [...this.state.subtitles];
    const target = newData.filter((item) => id === item.id)[0];
    if (target) {
      console.log(target);
      this.setState({
        subtitles: newData,
      });
    }
  }

  save(id) {
      const newData = [...this.state.subtitles];
      const target = newData.filter((item) => id === item.id)[0];
      editSub(this.state.connection, id, target, (error, response) => {      
	  if (target) {
	      delete target.editable;
	      this.setState({
		  subtitles: newData,
	      });
	      this.cacheData = newData.map((item) => ({
		  ...item,
	      }));
	  }
      });
  }

  cancel(id) {
    const newData = [...this.state.subtitles];
    const target = newData.filter((item) => id === item.id)[0];
    if (target) {
      Object.assign(target, this.cacheData.filter((item) => id === item.id)[0]);
      delete target.editable;
      this.setState({
        subtitles: newData,
      });
    }
  }

  renderColumns(text, record, column) {
    return (
      <EditableCell
        editable={record.editable}
        value={text}
        onChange={(value) => this.handleChange(value, record.id, column)}
      />
    );
  }

  render() {
    return (
      <div>
        <ReactPlayer
          url={this.state.project.url}
          className="react-player"
          ref={this.ref}
          controls
        />
        <Divider />
        <Spin spinning={this.state.loading}>
          <Button
            onClick={this.handleTime}
            type="primary"
            style={{
              marginBottom: 16,
            }}
          >
          Get time
          </Button>
          <Divider type="vertical" />
          <Button
            onClick={this.handleAdd}
            type="primary"
            style={{
              marginBottom: 16,
            }}
          >
          Add a row
          </Button>
          <Table bordered dataSource={this.state.subtitles} columns={this.columns} />
        </Spin>
      </div>
    );
  }
}

export default EditableTable;
