import React from 'react';
import { Layout } from 'antd';

import Sidebar from './containers/Menu/Sidebar';

import ListProjectLayout from './containers/Project/ListProjectLayout';

export default function ListProject() {
  return (
    <Layout>
      <Sidebar focus="listProject" />
      <ListProjectLayout />
    </Layout>
  );
}
