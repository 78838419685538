import React from 'react';
import { Layout } from 'antd';

import ProjectForm from './CreateProjectForm';

const { Header, Content, Footer } = Layout;

export default function CreateProjectLayout() {
  return (
    <Layout style={{
      marginLeft: 200,
    }}
    >
      <Header style={{
        background: '#fff', padding: 0,
      }}
      />
      <Content style={{
        margin: '24px 16px 0', overflow: 'initial',
      }}
      >
        <div style={{
          padding: 24, background: '#fff', textAlign: 'center',
        }}
        >
          <ProjectForm />
        </div>
      </Content>
      <Footer style={{
        textAlign: 'center',
      }}
      >
        AhaoSRT Project

      </Footer>
    </Layout>
  );
}
