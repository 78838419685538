/* eslint-disable consistent-return */
/* eslint-disable no-console */
const axios = require('axios');
const openSocket = require('socket.io-client');
const util = require('util');

const TOKEN_KEY = 'jwt';

export const initSocket = (id) => {
    const socket = openSocket(`https://socket.api-dev.ahaosrt.grosseteub.fr/project-${id}`);
    //const socket = openSocket(`http://192.168.0.22:1337/project-${id}`);
    return (socket);
};

export const loginProject = (socket, token, cb) => {
  socket.emit('user:login', token);
  socket.on('user:login:success', (resp) => cb(null, resp));
};

export const initProject = (socket, cb) => {
  socket.emit('project:load', 'all');
  socket.on('project:load:success', (project) => cb(null, project));
};

export const initUsers = (socket, users, cb) => {
  const parsedUsers = [];
  console.log(util.inspect(users));
  users.forEach((user) => {
      parsedUsers.push(user.id);
  });
  console.log(util.inspect(parsedUsers));
  socket.emit('user:getinfos', parsedUsers);
  socket.on('user:getinfos:success', (resp) => cb(null, resp));
  socket.on('user:getinfos:error', (resp) => cb(null, resp));
};

export const addSub = (socket, cb) => {
  socket.emit('subtitle:add', "00:00:00:000", "00:00:00:000", "Text");
  socket.on('subtitle:add:success', (sub) => cb(null, sub));
};

export const editSub = (socket, subtitleID, data, cb) => {
    socket.emit('subtitle:edit', subtitleID, data.begin, data.end, data.content);
    socket.on('subtitle:edit:success', (sub) => cb(null, sub));
};

export const connectProject = async (id) => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    console.log(token);
    const url = process.env.REACT_APP_ROOT_API;
    const route = '/project/login';
    const settings = {
      method: 'POST',
      url: url + route,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: `${token}`,
      },
      data: {
        projectID: id,
      },
    };
    try {
      const response = await axios(settings);
      if (response.status === 200) {
        console.log('200 OK');
        console.log(util.inspect(response.data.token));
        return ({
          success: true, error: '', token: response.data.token,
        });
      }
      console.log('200 NOT OK');
      return ({
        success: false, error: 'unknown',
      });
    } catch (e) {
      console.log('IN PROJECT');
      console.log(util.inspect(e));
      if (e.response && e.response.status === 422) {
        return ({
          success: false, error: 'The Token expired. Please logout.',
        });
      }
      return ({
        success: false, error: 'An error appeared while creating the project.',
      });
    }
  }
};

export const createProject = async (values) => {
  const token = localStorage.getItem(TOKEN_KEY);
  console.log(values.Title);
  console.log(values.Website);
  if (token) {
    console.log(token);
    const url = process.env.REACT_APP_ROOT_API;
    const route = '/project/create';
    const settings = {
      method: 'POST',
      url: url + route,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: `${token}`,
      },
      data: {
        url: values.Website,
        title: values.Title,
        description: values.Description,
      },
    };
    try {
      const response = await axios(settings);
      console.log(util.inspect(response));
      if (response.status === 201 && response.data.message === 'PROJECT_CREATED') {
        console.log(util.inspect(response));
        // eslint-disable-next-line indent
          console.log(response.data.projectID);
        return ({
          success: true, error: '',
        });
      }
      return ({
        success: false, error: 'unknown',
      });
    } catch (e) {
      console.log(util.inspect(e));
      if (e.response && e.response.status === 422) {
        return ({
          success: false, error: 'The Token expired. Please logout.',
        });
      }
      return ({
        success: false, error: 'An error appeared while creating the project.',
      });
    }
  }
};

export const listProject = async () => {
  const token = localStorage.getItem(TOKEN_KEY);
  const url = process.env.REACT_APP_ROOT_API;
  const route = '/project/list';
  const settings = {
    method: 'GET',
    url: url + route,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: `${token}`,
    },
  };
  try {
    const response = await axios(settings);
    console.log(util.inspect(response));
    if (response.status === 200) {
      console.log(util.inspect(response));
      return ({
        success: true, error: '', projects: response.data,
      });
    }
    return ({
      success: true, error: 'No Project Found.',
    });
  } catch (e) {
    console.log(util.inspect(e.response));
    if (e.response && e.response.status === 422) {
      return ({
        success: false, error: 'The Token expired. Please logout.',
      });
    }
    return ({
      success: false, error: 'Connection to the server Failed.',
    });
  }
};
