/* eslint-disable max-len */
import React from 'react';
import { Layout, Typography } from 'antd';

const { Header, Content, Footer } = Layout;

const { Title } = Typography;

export default function DashboardUser() {
  return (
    <Layout style={{
      marginLeft: 200,
    }}
    >
      <Header style={{
        background: '#fff', padding: 0,
      }}
      />
      <Content style={{
        margin: '24px 16px 0', overflow: 'initial',
      }}
      >
        <div style={{
          padding: 24, background: '#fff', textAlign: 'center',
        }}
        >
          <Typography>
            <Title>Welcome !</Title>
          </Typography>
        </div>
      </Content>
      <Footer style={{
        textAlign: 'center',
      }}
      >
AhaoSRT Project

      </Footer>
    </Layout>
  );
}
