import React from 'react';
import { Layout } from 'antd';

import Sidebar from './containers/Menu/Sidebar';

import ProfileInfo from './containers/Profile/ProfileInfo';

function Profile() {
  return (
    <Layout>
      <Sidebar focus="profile" />
      <ProfileInfo />
    </Layout>
  );
}

export default Profile;
