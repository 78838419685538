/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Layout, Descriptions, Spin } from 'antd';

import { userGet } from '../../../utils/user';

const { Header, Content, Footer } = Layout;

export default class ProfileInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      messageError: '',
      user: {

      },
    };
  }

  componentDidMount() {
    this.getUser();
  }

  // eslint-disable-next-line class-methods-use-this
  async getUser() {
    const check = await userGet();
    if (check.success) {
      const formatCreated = new Date(check.user.created);
      const formatUpdated = new Date(check.user.updated);
      check.user.created = formatCreated.toLocaleString('en-US');
      check.user.updated = formatUpdated.toLocaleString('en-US');
      this.setState({
        user: check.user,
      });
      this.toggle(false);
      return (true);
    }
    this.toggle(false);
    console.log('FAIL');
    return (false);
  }

  toggle = (value) => {
    this.setState({
      loading: value,
    });
  };

  setData = (value) => {
    this.setState({
      data: value,
    });
  };

  render() {
    return (
      <Layout style={{
        marginLeft: 200,
      }}
      >
        <Spin spinning={this.state.loading}>
          <Header style={{
            background: '#fff', padding: 0,
          }}
          />
          <Content style={{
            margin: '24px 16px 0', overflow: 'initial',
          }}
          >
            <div style={{
              padding: 24, background: '#fff', textAlign: 'center',
            }}
            >
              <Descriptions title="User Info">
                <Descriptions.Item label="UserName">{this.state.user.username}</Descriptions.Item>
                <Descriptions.Item label="Register Date">{this.state.user.created}</Descriptions.Item>
                <Descriptions.Item label="Last Connection">{this.state.user.updated}</Descriptions.Item>
                <Descriptions.Item label="ID">{this.state.user._id}</Descriptions.Item>
              </Descriptions>
            </div>
          </Content>
          <Footer style={{
            textAlign: 'center',
          }}
          >
        AhaoSRT Project
          </Footer>
        </Spin>
      </Layout>
    );
  }
}
