/* eslint-disable import/prefer-default-export */
const axios = require('axios');
const util = require('util');

const TOKEN_KEY = 'jwt';

export const userGet = async () => {
  const token = localStorage.getItem(TOKEN_KEY);
  const url = process.env.REACT_APP_ROOT_API;
  const route = '/user/get';
  const settings = {
    method: 'GET',
    url: url + route,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: `${token}`,
    },
  };
  try {
    const response = await axios(settings);
    // eslint-disable-next-line no-underscore-dangle
    if (response.status === 200 && response.data._id) {
      return ({
        success: true, error: '', user: response.data,
      });
    }
    return ({
      success: false, error: 'No User Found.',
    });
  } catch (e) {
    console.log(util.inspect(e.response));
    if (e.response && e.response.status === 422) {
      return ({
        success: false, error: 'The Token expired. Please logout.',
      });
    }
    return ({
      success: false, error: 'We are in maintenance! We will be back soon ;)',
    });
  }
};
