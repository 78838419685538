import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../utils/auth';

const PrivateRoute = ({ component: Component, ...rest }) => (

  <Route
    {...rest}
    render={(props) => (isLogin() ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            errormessage: 'The Token Expired',
          },
        }}
      />
    ))}
  />
);

export default PrivateRoute;
