const axios = require('axios');
// const util = require('util');

const TOKEN_KEY = 'jwt';

export const login = async (values) => {
  const url = process.env.REACT_APP_ROOT_API;
  const route = '/user/login';
  const settings = {
    method: 'POST',
    url: url + route,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: {
      username: values.userName,
      password: values.password,
    },
  };
  try {
    const response = await axios(settings);
    if (response.status === 200 && response.data.token) {
      localStorage.setItem(TOKEN_KEY, response.data.token);
      return ({
        success: true, error: '',
      });
    }
    return false;
  } catch (e) {
    if (e.response && e.response.status === 400) {
      return ({
        success: false, error: 'Bad credentials',
      });
    }
    return ({
      success: false, error: 'Connection to the server Failed.',
    });
  }
};

export const signup = async (values) => {
  const url = process.env.REACT_APP_ROOT_API;
  const route = '/user/register';
  const settings = {
    method: 'POST',
    url: url + route,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: {
      username: values.nickname,
      password: values.password,
    },
  };
  try {
    const response = await axios(settings);
    if (response.status === 201 && response.data.username) {
      return ({
        success: true, error: '',
      });
    }
    return false;
  } catch (e) {
    if (e.response.status === 400) {
      return ({
        success: false, error: 'The username is already taken',
      });
    }
    return ({
      success: false, error: 'Connection to the server Failed.',
    });
  }
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  // console.log('Logout Sucess');
};

export const isLogin = async () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    return (true);
  }
  return (false);
};
