import React from 'react';
import { Layout } from 'antd';

import Sidebar from './containers/Menu/Sidebar';
import DashboardUser from './containers/Dashboard/DashboardUser';

function Profile() {
  return (
    <Layout>
      <Sidebar focus="dashboard" />
      <DashboardUser />
    </Layout>
  );
}

export default Profile;
