import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Form, Icon, Input, Button, Checkbox, Typography, Spin } from 'antd';
import { login } from '../utils/auth';

const util = require('util');

const FormItem = Form.Item;

const { Title } = Typography;

const { Text } = Typography;

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToReferrer: false,
      loading: false,
      error: false,
      messageError: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line no-console
    console.log(util.inspect(this.props.location));
  }

  toggle = (value) => {
    this.setState({
      loading: value,
    });
  };

  redirect = (value) => {
    this.setState({
      redirectToReferrer: value,
    });
  };

  displayError = (props) => {
    this.setState({
      error: props.error,
    });
    this.setState({
      messageError: props.message,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.toggle(true);
    this.displayError({
      error: false, messageError: '',
    });
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const check = await login(values);
        if (check.success) {
          this.redirect(true);
        } else {
          this.displayError({
            error: true, message: check.error,
          });
          this.toggle(false);
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="login-form">
        {this.state.redirectToReferrer && (
        <Redirect to={{
          pathname: '/dashboard',
        }}
        />
        )}
        <Title level={2}>Ahao SRT - Login</Title>
        <Spin spinning={this.state.loading}>
          <Form onSubmit={this.handleSubmit}>
            <FormItem>
              {getFieldDecorator('userName', {
                rules: [
                  {
                    required: true, message: 'Please input your username!',
                  },
                ],
              })(
                <Input
                  prefix={(
                    <Icon
                      type="user"
                      style={{
                        fontSize: 13,
                      }}
                    />
                  )}
                  placeholder="Username"
                />,
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true, message: 'Please input your Password!',
                  },
                ],
              })(
                <Input
                  prefix={(
                    <Icon
                      type="lock"
                      style={{
                        fontSize: 13,
                      }}
                    />
                  )}
                  type="password"
                  placeholder="Password"
                />,
              )}
            </FormItem>
            {this.state.error && (
              <Text type="danger">{this.state.messageError}</Text>
            )}
            <FormItem>
              {getFieldDecorator('remember', {
                valuePropName: 'checked',
                initialValue: true,
              })(<Checkbox>Remember me</Checkbox>)}
              <a className="login-form-forgot" href="grossteub.fr">
                Forgot password
              </a>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                onClick={this.handleSubmit}
              >
                Login
              </Button>
              <Link to="/signup">Register now!</Link>
            </FormItem>
          </Form>
        </Spin>
      </div>
    );
  }
}

const Login = Form.create()(LoginForm);

export default Login;
