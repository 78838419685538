import React, { Component } from 'react';
import { Layout, Menu, Icon } from 'antd';
import { Link } from 'react-router-dom';

import { logout, isLogin } from '../../../utils/auth';

import ahaologo from './ahaologo.png';

const { Sider } = Layout;

const { SubMenu } = Menu;

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // eslint-disable-next-line react/no-unused-state
	isLogin: isLogin(),
        collapsed: false
    };
  }

    onCollapse = collapsed => {
	console.log(collapsed);
	this.setState({ collapsed });
    };
    
  handleLogout = () => {
    logout();
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      isLogin: false,
    });
  }

  render() {
      return (
	  <Layout>
      <div className="sidebar">
              <Sider
	  collapsible
	  collapsed={this.state.collapsed}
	  onCollapse={this.onCollapse}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
          }}
              >
            <div className="ahaologo">
            <img src={ahaologo} alt="ahaologo " width="100" height="100" style={{display: 'block', "margin-left": 'auto', "margin-right": 'auto'}} />
          </div>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={[this.props.focus]} defaultOpenKeys={['sub1', 'sub2']}>
            <Menu.Item key="dashboard">
              <Link
                to="/dashboard"
                state={{
                  page: 'dashboard',
                }}
              >
                <Icon type="dashboard" />
                <span className="nav-text">
                  Dashboard
                </span>
              </Link>
            </Menu.Item>
            <SubMenu
              key="sub1"
              title={(
                <span>
                  <Icon type="user" />
                  <span>Profile</span>
                </span>
              )}
            >
              <Menu.Item key="profile">
                <Link to="/profile">
                  Informations
                </Link>
              </Menu.Item>
              <Menu.Item key="settings">Settings</Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub2"
              title={(
                <div>
                  <Icon type="project" />
                  <span>Projects</span>
                </div>
              )}
            >
              <Menu.Item key="createProject">
                <Link to="/project/create">
                  Create Project
                </Link>
              </Menu.Item>
              <Menu.Item key="listProject">
                <Link to="/project/list">
                  My Projects
                </Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="logout">
              <Link to="/login" onClick={() => this.handleLogout()}>
                <Icon type="logout" />
                <span className="nav-text">
                  Logout
                </span>
              </Link>
            </Menu.Item>
              </Menu>
              </Sider>
	      </div>
	      </Layout>
    );
  }
}


export default Sidebar;
