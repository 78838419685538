import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Translate from './components/Translate';
import Profile from './components/Profile';
import Login from './components/Login';
import Signup from './components/Signup';
import DashboardUser from './components/Dashboard';
import CreateProject from './components/CreateProject';
import ListProject from './components/ListProject';
import PrivateRoute from './components/PrivateRoute';
// import PublicRoute from './components/PublicRoute';

import './App.css';

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={Signup} path="/signup" />
        <Route render={(props) => <Login {...props} />} path="/login" />
        <PrivateRoute exact component={DashboardUser} path="/" />
        <PrivateRoute exact component={DashboardUser} path="/dashboard" />
        <PrivateRoute exact component={Profile} path="/profile" />
        <PrivateRoute exact component={CreateProject} path="/project/create" />
        <PrivateRoute exact component={ListProject} path="/project/list" />
        <PrivateRoute exact component={Translate} path="/project/:id" />
      </Switch>
    </BrowserRouter>
  );
}
