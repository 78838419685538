/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
import React from 'react';
import { Card, Col, Row, Icon, Avatar, Spin, Alert } from 'antd';
import { listProject } from '../../../utils/project';
import { Link } from 'react-router-dom';

const { Meta } = Card;

function ProjectList(props) {
  const projects = props.project;
  if (props.project.length === 0 && !props.loading) {
    return (
      <Alert
        message="You have no project yet."
        description="You can create project a just here !"
        type="info"
        showIcon
      />
    );
  }

  const list = projects.map((item) => (
    <Col span={6} className="gutter-row" key={item._id} >
      <Card
        style={{
          width: 300,
          margin: 6,
        }}
        cover={(
          <img
            alt="example"
            src="https://wallpaperplay.com/walls/full/a/9/9/3080.jpg"
          />
        )}
        actions={[
          <div>
            <Icon type="team" key="team" />
            <span>
              {' '}
              {item.users.length + 1}
            </span>
          </div>,
          <Link
          to={'/project/' + item._id}
          state={{
            page: 'projet',
          }}
        > <Icon type="project" key="project" /></Link>,
          <Icon type="edit" key="edit" />,
        ]}
      >
        <Meta
          avatar={<Avatar src="https://wallpaperplay.com/walls/full/a/9/9/3080.jpg" />}
          title={item.title}
          description={item.description}
        />
      </Card>
    </Col>
  ));
  return (
    list
  );
}


export default class ListProjectTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      messageError: '',
      projects: [],
      data: false,
    };
  }

  componentDidMount() {
    this.getProjects();
  }

  // eslint-disable-next-line class-methods-use-this
  async getProjects() {
    const check = await listProject();
    if (check.success) {
      this.setState({
        projects: check.projects,
      });
      this.toggle(false);
      return (true);
    }
    this.toggle(false);
    console.log('FAIL');
    return (false);
  }

  toggle = (value) => {
    this.setState({
      loading: value,
    });
  };

  setData = (value) => {
    this.setState({
      data: value,
    });
  };

  render() {
    return (
      <Spin spinning={this.state.loading}>
        <div style={{
          padding: '30px',
        }}
        >
          <Row
            gutter={12}
            style={{
              marginBottom: 30,
            }}
          >
            <ProjectList project={this.state.projects} loading={this.state.loading} />
          </Row>
        </div>
      </Spin>
    );
  }
}
