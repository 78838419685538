import React from 'react';
import { Layout } from 'antd';
// import { useState } from 'react';

import EditableTable from './EditableTable';

const { Header, Content, Footer } = Layout;

const TranslateTable = ({ Projectid }) => (
  <Layout style={{
    marginLeft: 200,
  }}
  >
    <Header style={{
      background: '#fff', padding: 5,
    }}
    />
    <Content style={{
      margin: '24px 16px 0', overflow: 'initial',
    }}
    >
      <div style={{
        padding: 24, background: '#fff', textAlign: 'center',
      }}
      >
        <EditableTable Projectid={Projectid} />
      </div>
    </Content>
    <Footer style={{
      textAlign: 'center',
    }}
    >
      AhaoSRT Project
    </Footer>
  </Layout>
);

export default TranslateTable;
