import React from 'react';
import { Form, Input, Tooltip, Icon, Checkbox, Button, Typography, Spin } from 'antd';
import { Redirect } from 'react-router-dom';
import { signup } from '../utils/auth';

const FormItem = Form.Item;

const { Title } = Typography;

const { Text } = Typography;

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      loading: false,
      error: false,
      messageError: '',
      redirectToReferrer: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggle = (value) => {
    this.setState({
      loading: value,
    });
  };

  redirect = (value) => {
    this.setState({
      redirectToReferrer: value,
    });
  };

  displayError = (props) => {
    this.setState({
      error: props.error,
    });
    this.setState({
      messageError: props.message,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.toggle(true);
    this.displayError({
      error: false, messageError: '',
    });
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const check = await signup(values);
        if (check.success) {
          this.redirect(true);
        } else {
          this.displayError({
            error: true, message: check.error,
          });
          this.toggle(false);
        }
      } else {
        this.toggle(false);
      }
    });
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      confirmDirty: this.state.confirmDirty || !!value,
    });
  };

  checkPassword = (rule, value, callback) => {
    const { form } = this.props;

    const regexpwd = RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
    );
    if (!regexpwd.test(value)) {
      callback(
        'Due to our security policy, Your password should contain at least: 8 characters, 1 digit (0-9), 1 lowercase character (a-z), 1 uppercase character (A-Z) and 1 special character',
      );
    } else if (value && value !== form.getFieldValue('password')) {
      callback('The 2 passwords are not the same.');
    } else {
      callback();
    }
  };

  checkUsername = (rule, value, callback) => {
    const asciiregexp = RegExp('^[a-zA-Z0-9_-]*$');

    if (!asciiregexp.test(value)) {
      callback('Your Username should have only alphanumeric characters.');
    }
    callback();
  };

  checkConfirm = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], {
        force: true,
      });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 6,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 14,
        },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 14,
          offset: 6,
        },
      },
    };

    return (
      <div className="signup-form">
        {this.state.redirectToReferrer && (
        <Redirect to={{
          pathname: '/dashboard',
          state: {
            signup: true,
          },
        }}
        />
        )}
        <Title level={2}>Ahao SRT - Signup</Title>
        <Spin spinning={this.state.loading}>
          <Form onSubmit={this.handleSubmit}>
            {/* <FormItem
          {...formItemLayout}
          label="E-mail"
          hasFeedback
        >
          {getFieldDecorator('email', {
            rules: [{
              type: 'email', message: 'The input is not valid E-mail!',
            }, {
              required: true, message: 'Please input your E-mail!',
            }],
          })(
            <Input />
          )}
          </FormItem> */}
            <FormItem
              {...formItemLayout}
              label={(
                <span>
                  Nickname&nbsp;
                  <Tooltip title="What do you want other to call you?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              )}
              hasFeedback
            >
              {getFieldDecorator('nickname', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your nickname!',
                    whitespace: true,
                  },
                  {
                    validator: this.checkUsername,
                  },
                ],
              })(<Input />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Password" hasFeedback>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  {
                    validator: this.checkConfirm,
                  },
                ],
              })(<Input type="password" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Confirm Password" hasFeedback>
              {getFieldDecorator('confirm', {
                rules: [
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  {
                    validator: this.checkPassword,
                  },
                ],
              })(<Input type="password" onBlur={this.handleConfirmBlur} />)}
            </FormItem>
            {this.state.error && (
              <Text type="danger">{this.state.messageError}</Text>
            )}
            <Form.Item {...tailFormItemLayout}>
              {getFieldDecorator('agreement', {
                valuePropName: 'checked',
              })(
                <Checkbox>
                  I have read the
                  {' '}
                  <a href="grosseteub.fr">agreement</a>
                </Checkbox>,
              )}
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={this.handleSubmit}
              >
                Register
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    );
  }
}

const WrappedRegistrationForm = Form.create()(RegistrationForm);

export default WrappedRegistrationForm;
