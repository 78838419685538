import React from 'react';

import { Layout } from 'antd';

import { useParams } from 'react-router-dom';
import TranslateTable from './containers/Translate/TranslateTable';

import Sidebar from './containers/Menu/Sidebar';

export default function Translate() {
  const { id } = useParams();
  return (
    <Layout>
      <Sidebar />
      <TranslateTable Projectid={id} />
    </Layout>
  );
}
