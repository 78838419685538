import React from 'react';
import { Layout } from 'antd';

import Sidebar from './containers/Menu/Sidebar';

import CreateProjectLayout from './containers/Project/CreateProjectLayout';

function CreateProject() {
  return (
    <Layout>
      <Sidebar focus="createProject" />
      <CreateProjectLayout />
    </Layout>
  );
}

export default CreateProject;
