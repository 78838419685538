/* eslint-disable max-len */
import React from 'react';
import { Form, Input, Tooltip, Icon, Button, Spin, Upload, notification } from 'antd';

import { createProject } from '../../../utils/project';

// import DynamicField from './CreateProjectDynamicFields';

const FormItem = Form.Item;

let uuid = 0;

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      loading: false,
    };
  }

  remove = (k) => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    // We need at least one passenger
    if (keys.length === 0) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter((key) => key !== k),
    });
  }

  add = () => {
    uuid += 1;
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(uuid);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
  }

  toggle = (value) => {
    this.setState({
      loading: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.toggle(true);
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        // eslint-disable-next-line no-console
        console.log('Received values of form: ', values);
        const check = await createProject(values);
        if (check.success) {
          this.props.form.resetFields();
          this.toggle(false);
          notification.open({
            message: 'Success',
            description:
              'The Project has been successfully created.',
            icon: <Icon
              type="check-circle"
              style={{
                color: '#059214',
              }}
            />,
          });
        } else {
          this.toggle(false);
          notification.open({
            message: 'Error',
            description:
              check.error,
            icon: <Icon
              type="close-circle"
              style={{
                color: '#D50000',
              }}
            />,
          });
        }
      } else {
        this.toggle(false);
        notification.open({
          message: 'Error',
          description:
              'Please check again the fields.',
          icon: <Icon
            type="close-circle"
            style={{
              color: '#D50000',
            }}
          />,
        });
      }
    });
  }

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      confirmDirty: this.state.confirmDirty || !!value,
    });
  }

  checkTitle = (rule, value, callback) => {
    const titleRegexp = RegExp('^[-?_A-Za-z0-9]+(?:[\\s-][-?_A-Za-z0-9]+)*$');
    if (!titleRegexp.test(value)) {
      callback('The title should have only alphanumeric characters. We accept also "?-_" characters. Can not start or end with a whitespace');
    }
    callback();
  };

  checkDescription = (rule, value, callback) => {
    const titleRegexp = RegExp('^[-?_A-Za-z0-9]+(?:[\\s-][-?_A-Za-z0-9]+)*$');
    if (!titleRegexp.test(value)) {
      callback('The Description should have only alphanumeric characters. We accept also "?-_" characters. Can not start or end with a whitespace');
    }
    callback();
  };

  checkUrl = (rule, value, callback) => {
    const urlrgx = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
    if (!urlrgx.test(value)) {
      callback('This is not a valid URL.');
    }
    callback();
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 4,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 20,
        },
      },
    };

    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: {
          span: 24, offset: 0,
        },
        sm: {
          span: 20, offset: 4,
        },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 14,
          offset: 6,
        },
      },
    };

    getFieldDecorator('keys', {
      initialValue: [],
    });

    const keys = getFieldValue('keys');

    const formItems = keys.map((k, index) => (
      <FormItem
        {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
        label={index === 0 ? 'Collaborator' : ''}
        required={false}
        key={k}
      >
        {getFieldDecorator(`names-${k}`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [{
            required: true,
            whitespace: true,
            message: 'Please input the collaborator username.',
          }],
        })(
          <Input
            placeholder="Collaborator"
            style={{
              width: '60%', marginRight: 8,
            }}
          />,
        )}
        {keys.length > 0 ? (
          <Icon
            className="dynamic-delete-button"
            type="minus-circle-o"
            disabled={keys.length === 0}
            onClick={() => this.remove(k)}
          />
        ) : null}
      </FormItem>
    ));

    return (
      <Spin spinning={this.state.loading}>
        <Form onSubmit={this.handleSubmit}>
          <FormItem
            {...formItemLayout}
            label={(
              <span>
              Name&nbsp;
                <Tooltip title="Name of your project">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
          )}
            hasFeedback
          >
            {getFieldDecorator('Title', {
              rules: [{
                required: true, message: 'Please Input a Project Title.', whitespace: true,
              },
              {
                validator: this.checkTitle,
              }],
            })(
              <Input />,
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={(
              <span>
              Video URL&nbsp;
                <Tooltip title="Video URL (exemple : youtube, viadeo...">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
          )}
            hasFeedback
          >
            {getFieldDecorator('Website', {
              rules: [{
                required: true, message: 'Please input a valid Website.', whitespace: true,
              },
              {
                validator: this.checkUrl,
              }],
            })(
              <Input placeholder="http://youtube.com/123" />,
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={(
              <span>
              Description&nbsp;
                <Tooltip title="Description of your project">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
          )}
            hasFeedback
          >
            {getFieldDecorator('Description', {
              rules: [
                {
                  validator: this.checkDescription,
                }],
            })(
              <Input />,
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Subtitles "
          >
            <div className="dropbox">
              {getFieldDecorator('dragger', {
                valuePropName: 'fileList',
                getValueFromEvent: this.normFile,
              })(
                <Upload.Dragger name="files" action="/upload.do">
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">Click or drag subtitle file to this area to upload</p>
                  <p className="ant-upload-hint">Only .srt valid files are accepted.</p>
                </Upload.Dragger>,
              )}
            </div>
          </FormItem>
          <FormItem>
            {formItems}
            <FormItem {...formItemLayoutWithOutLabel}>
              <Button
                type="dashed"
                onClick={this.add}
                style={{
                  width: '60%',
                }}
              >
                <Icon type="plus" />
                {' '}
              Add Collaborator
              </Button>
            </FormItem>
          </FormItem>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" onClick={this.handleSubmit}>
                Create
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    );
  }
}

const CreateProjectForm = Form.create()(RegistrationForm);

export default CreateProjectForm;
